import React, { FC } from 'react'
import Head from 'next/head'
import queryString from 'query-string'
import { toSEOStringFromQuery } from '../../utilities/SEOUntilities'
import { useAppRouter } from '../../hooks/UseAppRouter'
import { useServerService } from '../FreecProvider'
import { getIsServer } from '../../utilities/SSR'
import { removeEndSplash } from '../../utilities/StringUtils'

export const EliminateQueryCanonical: FC<{ meaningfulQueryKeyList?: string[]; rootDomain?: string }> = ({
  meaningfulQueryKeyList,
  rootDomain
}) => {
  const router = useAppRouter()
  const serverService = useServerService()

  const realPath = getIsServer() ? serverService?.originalUrl : (location?.pathname || '').split('?')[0]

  const eliminatedQuery = Object.keys(router.query).reduce(
    (query, key) => ({
      ...query,
      ...((meaningfulQueryKeyList || []).includes(key) ? { [key]: toSEOStringFromQuery(router.query[key]) } : {})
    }),
    {}
  )
  const searchString = queryString.stringify(eliminatedQuery, { arrayFormat: 'bracket', encode: false })
  const loc = new URL(process.env.NEXT_PUBLIC_APP_DOMAIN + (realPath || '/'))
  const fullUrl = rootDomain || process.env.NEXT_PUBLIC_APP_DOMAIN + loc.pathname + (searchString ? `?${searchString}` : '')

  return (
    <Head>
      <link rel="canonical" href={removeEndSplash(fullUrl)} />
    </Head>
  )
}
