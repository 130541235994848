import { FC, useState } from 'react'
type AsyncComponentProps<T = {}> = FC<{
  action: (args: T) => Promise<any | void>
  render: FC<{ run: (args: T) => Promise<any | void>; loading: boolean }>
}>
const AsyncAction: AsyncComponentProps = ({ action, render }) => {
  const [loading, setLoading] = useState(false)
  return render({
    run: (args) => {
      setLoading(true)
      return action(args).finally(() => {
        setLoading(false)
      })
    },
    loading
  })
}
export default AsyncAction
