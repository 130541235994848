export const API_URL_FORGOT_PW_SEND_EMAIL = 'api/v2/candidates/auths/forgot-password/send'
export const API_URL_FORGOT_PW_VERIFY_TOKEN = 'api/v2/candidates/auths/forgot-password/verify'
export const API_URL_VERIFY_REGISTER = '/api/v2/candidates/auths/register/verify'
export const API_URL_RESET_PASSWORD = 'api/v2/candidates/auths/forgot-password'
export const API_URL_UPDATE_PASSWORD = '/api/v2/candidates/accounts/reset-password'

export const API_URL_GET_ANONYMOUS_JOB = '/api/v2/public/jobs'
export const API_URL_PUBLIC_RECOMMEND_JOB = '/api/v2/public/jobs/recommend_jobs'
export const API_URL_CANDIDATES_JOB = '/api/v2/candidates/jobs'
export const API_URL_ANONYMOUS_JOB_DETAIL = (jobId: string | number) => `/api/v2/public/jobs/${jobId}`
export const API_URL_CANDIDATES_JOB_DETAIL = (jobId: string | number) => `/api/v2/candidates/jobs/${jobId}`
export const API_URL_CANDIDATES_JOB_DETAIL_SIMILAR = (jobId: string | number) => `/api/v2/public/jobs/${jobId}/similar`
export const API_URL_CANDIDATES_MYJOB_SAVE = '/api/v2/candidates/jobs/save'

export const API_URL_LOGIN = '/api/v2/candidates/auths/login'
export const API_URL_RESEND_EMAIL = '/api/v2/candidates/auths/register/resend'
export const API_URL_CANDIDATES_LOGIN_SOCIAL = '/api/v2/candidates/auths/login/social'
export const API_URL_CANDIDATES_REGISTER_SOCIAL = '/api/v2/candidates/auths/register/social'
export const API_URL_CANDIDATE_REGISTER = '/api/v2/candidates/auths/register'
export const API_URL_CANDIDATES_GOOGLE_ONE_TAP = '/api/v2/candidates/auths/sign_in_one_tap'

export const API_URL_APPLY_JOB_WITH_SOCIAL_WITHOUT_CV = '/api/v2/candidates/auths/quick_register/social'
export const API_URL_APPLY_JOB_WITH_EMAIL_WITHOUT_CV = '/api/v2/candidates/auths/quick_register/email'
export const API_URL_SHARE_CV_ONLINE = (slug: string | string[]) => `/api/v2/public/profiles/share_cv_online/${slug}`

export const API_URL_GET_PROFILE_SHARE_CV = (token: string) => `/api/v2/public/profiles/share_cv/${token}`
export const API_URL_GET_PROFILE_CONSULTANT_SHARE_CV = (token: string) => `/api/v2/public/profiles/consultant_share_cv/${token}`
export const API_URL_FEEDBACKS_SHARE_CV = (token: string) => `/api/v2/public/applicant_notes?token=${token}`

export const API_URL_GET_RELATIVE_KEY_WORDS = (keyword: string) => `/api/v2/public/keywords/related/${keyword}`
export const API_URL_GET_KEY_WORDS_LIST = `/api/v2/public/keywords`
export const API_URL_TRACKING_CV_ONLINE_AHOY = `/api/v2/public/ahoy`
