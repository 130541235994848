import env from './env'

export * as RESOURCE_IMAGE_FREEC_LOGO_EN from '/images/logo_candidate_en.svg'

export * as RESOURCE_IMAGE_FREEC_LOGO_VN from '/images/logo_candidate_vn.svg'
export * as RESOURCE_IMAGE_DEFAULT_FREEC_LOGO from '/images/freec-logo-blue.svg'

export const PAGE_ITEMS_SEARCH_JOBS = 20
export const PAGE_ITEMS_JOB_DETAIL_SIMILAR_JOBS = 6
export const PAGE_ITEMS_COMPANY_DETAIL_JOBS = 20
export const PAGE_ITEMS_COMPANY_LIST_PUBLIC = 30

export const LOGIN_PROVIDER_GOOGLE = 1
export const LOGIN_PROVIDER_FACEBOOK = 0
export const LOGIN_PROVIDER_LINKEDIN = 4

export * as RESOURCE_IMAGE_DOWNLOAD_ANDROID_BLACK from '/images/logo_google_play_black.png'
export * as RESOURCE_IMAGE_DOWNLOAD_ANDROID from '/images/google_play.svg'
export * as RESOURCE_IMAGE_DOWNLOAD_IOS_BLACK from '/images/logo_apple_store_black.png'
export * as RESOURCE_IMAGE_DOWNLOAD_IOS from '/images/apple_store.svg'
export * as RESOURCE_IMAGE_LOGO_GOV from '/images/logo_gov_red.png'
export * as RESOURCE_IMAGE_FREEC_LOGO from '/images/freec-logo-blue.svg'
export * as RESOURCE_IMAGE_FREEC_LOGO_WHITE from '/images/freec-logo-white.svg'
export * as RESOURCE_IMAGE_FREECRACY_LOGO from '/images/freecracy-logo.svg'
export * as RESOURCE_IMAGE_FREECRACY_LOGO_WHITE from '/images/freecracy-logo-white.svg'
export * as RESOURCE_IMAGE_ARROW_RIGHT_BLUE from '/images/arrow-right-blue.svg'

export * as RESOURCE_IMAGE_FACEBOOK from '/images/facebook.svg'
export * as RESOURCE_IMAGE_YOUTUBE from '/images/youtube.svg'
export * as RESOURCE_IMAGE_LINKEDIN from '/images/linkedin.svg'
export * as RESOURCE_IMAGE_INSTAGRAM from '/images/instagram.svg'
export * as RESOURCE_IMAGE_ICON_BUILDINGS from '/images/buildings.svg'
export * as RESOURCE_IMAGE_EN_FLAG from '/images/en_flag.png'
export * as RESOURCE_IMAGE_VN_FLAG from '/images/vn_flag.png'

export const optionsGoogleOneTap = {
  client_id: env.PUBLIC_GOOGLE_APP_ID,
  auto_select: false,
  cancel_on_tap_outside: false,
  context: 'signin'
}
