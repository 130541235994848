import React from 'react'
import { Trans } from 'react-i18next'
import { notification } from '../components/share/ANTD/Notification'
import { LINK_URL_FORGOT_PASSWORD } from '../utilities/LinkURL'

export const LIST_URL = {
  LOGIN: 'LOGIN',
  LOGIN_WITH_SOCIAL: 'LOGIN_WITH_SOCIAL',
  REGISTER_CANDIDATE: 'REGISTER_CANDIDATE',
  REGISTER_CANDIDATE_SOCIAL: 'REGISTER_CANDIDATE_SOCIAL',
  REGISTER_RESEND: 'REGISTER_RESEND',
  REGISTER_VERIFY: 'REGISTER_VERIFY',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SEND: 'FORGOT_PASSWORD_SEND',
  FORGOT_PASSWORD_VERIFY: 'FORGOT_PASSWORD_VERIFY',
  RESET_PASSWORD: 'RESET_PASSWORD',

  CONSULTANTS_JOB_ORDER: 'CONSULTANTS_JOB_ORDER',
  CONSULTANTS_JOB_ORDER_ACTION_RECRUITERS: 'CONSULTANTS_JOB_ORDER_ACTION_RECRUITERS'
}

export const handleErrorAPIFromRequest = ({ error, router = undefined, page, callbackAction, callbackType = undefined }) => {
  const response = error?.response || {}
  const { status, data } = response

  if (!status || !data) return null
  const keys = []
  Object.keys(data?.messages || {}).forEach((key) => {
    if (key !== 'user_id' && key !== 'payload') {
      keys.push(...data?.messages[key])
    }
  })

  if (status === 422) {
    if (
      data.messages.includes('Email This email does not exist, please check again!') ||
      data.messages.includes('Email này không tồn tại, vui lòng kiểm tra lại!')
    ) {
      return callbackAction(<Trans i18nKey="notification:email_does_not_exist" />)
    }
    if (data.messages[0].includes('Email Invalid format')) {
      return callbackAction(<Trans i18nKey="form:validate_email_format" />)
    }
    return notification['error']({
      message: data?.messages[0]
    })
  }
  if (status === 400 && data.messages?.email.includes('Email This email does not exist, please check again!')) {
    return callbackAction(<Trans i18nKey="notification:email_does_not_exist" />)
  }

  if (status === 400 || status === 401 || status === 422) {
    keys.forEach((element) => {
      /*
       * Common handle error from API
       * - Step 1: Follow variable from `LIST_URL`
       * - Step 2: Use
       *            handleErrorAPIFromRequest({
       *              error,
       *              page: LIST_URL.XXX,
       *              callbackAction: (errorText) => XXX(errorText)
       *            })
       *           for catch((error) from wrapper each page
       */

      if (page === LIST_URL.LOGIN) {
        switch (element) {
          case 'NOT_VERIFIED_ACCOUNT':
          case 'NOT_VERIFIED_USER':
            // callbackAction(<Trans i18nKey="notification:please_verify_account" />)
            callbackType(element)
            break

          case 'INVALID_CREDENTAILS':
          case 'ACCOUNT_NOT_EXIST':
            callbackAction(<Trans i18nKey="form:validate_login_fail" />)
            break

          case 'INVALID_PASSWORD_HASH':
            callbackAction(
              <Trans i18nKey="notification:validate_invalid_password_hash">
                <a
                  onClick={() => {
                    if (router) {
                      router.push(LINK_URL_FORGOT_PASSWORD)
                    }
                  }}
                  className="color-main f-weight"
                />
              </Trans>
            )
            break

          case 'WRONG_USER_TYPE':
            callbackAction(<Trans i18nKey="notification:validate_wrong_user_type" />)
            break

          case 'ACCOUNT_IS_BLOCKED':
          case 'COMPANY_IS_BLOCKED':
            callbackAction(<Trans i18nKey="form:company_is_blocked" />)
            break

          default:
            callbackAction(<Trans i18nKey="form:validate_login_fail" />)
            break
        }
      }

      if (page === LIST_URL.LOGIN_WITH_SOCIAL) {
        switch (element) {
          case 'INVALID_CREDENTAILS':
          case 'ACCOUNT_NOT_EXIST':
            callbackAction(<Trans i18nKey="form:validate_login_fail" />)
            break

          case 'WRONG_USER_TYPE':
            callbackAction(<Trans i18nKey="notification:validate_wrong_user_type" />)
            break

          case 'ACCOUNT_IS_BLOCKED':
          case 'COMPANY_IS_BLOCKED':
            callbackAction(<Trans i18nKey="form:company_is_blocked" />, '')
            break
          case 'IS_NOT_BELONGS_TO_RECRUITER':
            callbackAction(<Trans i18nKey="notification:validate_belongs_to_recruiter" />)
            break

          default:
            callbackAction(<Trans i18nKey="form:validate_login_fail" />)
            break
        }
      }

      if (
        [LIST_URL.REGISTER_VERIFY, LIST_URL.REGISTER_RESEND, LIST_URL.REGISTER_CANDIDATE_SOCIAL, LIST_URL.REGISTER_CANDIDATE].includes(page)
      ) {
        switch (element) {
          case 'INVALID_USER':
          case 'INVALID_CREDENTAILS':
          case 'INVALID_PASSWORD_HASH':
            callbackAction(<Trans i18nKey="notification:validate_email_unique" />, '')
            break

          case 'EMAIL_BELONG_TO_RECRUITER':
            callbackAction(<Trans i18nKey="notification:this_email_is_reristerd_for_recruiter" />, '')
            break

          case 'INVALID_TOKEN':
            callbackAction(<Trans i18nKey="notification:notification" />, <Trans i18nKey="notification:validate_invalid_token" />)
            callbackType(element)
            break

          case 'EXISTED_NAME':
            callbackAction(<Trans i18nKey="notification:validate_name_unique" />, '')
            break

          default:
            callbackAction(<Trans i18nKey="notification:validate_email_unique" />, '')
            break
        }
      }

      if (page === LIST_URL.FORGOT_PASSWORD) {
        switch (element) {
          case 'INVALID_TOKEN':
            callbackAction(<Trans i18nKey="notification:validate_invalid_token" />)
            callbackType(element)
            break

          case 'COMPANY_IS_BLOCKED':
          case 'ACCOUNT_IS_BLOCKED':
            callbackAction(<Trans i18nKey="form:company_is_blocked" />)
            break

          case 'NOT_VERIFIED_USER':
            callbackAction(<Trans i18nKey="notification:please_verify_account" />)
            break

          default:
            callbackAction(<Trans i18nKey="notification:please_verify_account" />)
            break
        }
      }

      if (page === LIST_URL.FORGOT_PASSWORD_SEND) {
        switch (element) {
          case 'ACCOUNT_IS_BLOCKED':
          case 'COMPANY_IS_BLOCKED':
            callbackAction(<Trans i18nKey="form:company_is_blocked" />)
            break

          case 'INVALID_TOKEN':
            callbackAction(<Trans i18nKey="notification:validate_invalid_token" />)
            callbackType(element)
            break
          case 'INVALID_EMAIL':
            callbackAction(<Trans i18nKey="notification:email_does_not_exist" />)
            callbackType(element)
            break

          default:
            callbackAction(<Trans i18nKey="notification:notification" />, '')
            break
        }
      }

      if (page === LIST_URL.FORGOT_PASSWORD_VERIFY) {
        switch (element) {
          case 'NOT_VERIFIED_USER':
            callbackAction(<Trans i18nKey="notification:notification" />, <Trans i18nKey="notification:please_verify_account" />)
            callbackType(element)
            break

          case 'ACCOUNT_IS_BLOCKED':
          case 'COMPANY_IS_BLOCKED':
            callbackAction(<Trans i18nKey="notification:notification" />, <Trans i18nKey="form:company_is_blocked" />)
            break

          case 'INVALID_TOKEN':
            callbackAction(<Trans i18nKey="notification:notification" />, <Trans i18nKey="notification:validate_invalid_token" />)
            callbackType(element)
            break

          default:
            callbackAction(<Trans i18nKey="notification:notification" />, '')
            break
        }
      }

      if (page === LIST_URL.RESET_PASSWORD) {
        switch (element) {
          case 'MISSING_CURRENT_PASSWORD':
            callbackAction(<Trans i18nKey="page_account_change_password:password_was_wrong" />, '')
            break

          case 'INVALID_CREDENTAILS':
            callbackAction(<Trans i18nKey="page_account_change_password:password_was_wrong" />, '')
            break

          case 'INVALID_PASSWORD_HASH':
            callbackAction(<Trans i18nKey="page_account_change_password:password_was_wrong" />, '')
            break

          default:
            callbackAction('', '')
            break
        }
      }

      // CONSULTANTS
      if (page === LIST_URL.CONSULTANTS_JOB_ORDER) {
        switch (element) {
          case 'ALREADY_APPLIED_JOB':
            callbackAction(<Trans i18nKey="notification:candidate_already_applied_jobs" />, '')
            break

          default:
            callbackAction(<Trans i18nKey="notification:candidate_already_applied_jobs" />, '')
            break
        }
      }

      if (page === LIST_URL.CONSULTANTS_JOB_ORDER_ACTION_RECRUITERS) {
        switch (element) {
          case 'recruiter_id existed':
          case 'RECRUITER_ID_EXISTED':
            callbackAction(<Trans i18nKey="notification:candidate_duplicate_add_recruiter" />, '')
            break

          default:
            callbackAction(<Trans i18nKey="notification:candidate_duplicate_add_recruiter" />, '')
            break
        }
      }
    })
  }
}
