import env from './env'

export const getlinkedinCode = (code) => {
  return {
    code,
    redirect_uri:
      {
        production: 'https://freec.asia/linkedin',
        staging: 'https://freectech.com/linkedin',
        develop: 'http://localhost:3000/linkedin'
      }[env.PUBLIC_APP_ENVIRONMENT] || 'http://localhost:3000/linkedin'
  }
}
